import React, { useState, useRef, useCallback, useEffect } from 'react';
import ReactFlow, { ReactFlowProvider, addEdge, applyEdgeChanges, applyNodeChanges, useNodesState, useEdgesState, MarkerType, updateEdge } from '../reactflow';
import '../reactflow/dist/style.css';
import Sidebar from './Sidebar';
import './index.css';
import Properties from './PropertyPanel';
import NotificationBar from '../NotificationBar';
import Header from '../Header/Header';
import SimpleFloatingEdge from './CustomeNode/SimpleFloatingEdge';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ContextMenu, getProjectByID, publishJSON, saveProject, createBlobContainer } from '../../Services/Services';
import ResponsiveDialog from '../ResponsiveDialog';
import Select from 'react-select';
import { useNavigate } from 'react-router-dom';
import AddNew from '../../assets/svg/plus.svg';
import Upload from '../../assets/svg/upload.svg';
import Save from '../../assets/svg/save.svg';
import SaveAs from '../../assets/svg/saveas.svg';
import Close from '../../assets/svg/x.svg';
import Fork from '../../assets/svg/forkRight.svg';
import Busy from '../../assets/svg/closeConnection.svg';
import SpeechToText from '../../assets/svg/sidebarIcons/speechToText.svg';
import TextToSpeech from '../../assets/svg/sidebarIcons/textToSpeech.svg';
import PreProcess from '../../assets/svg/sidebarIcons/preProcess.svg';
import SubtitleWriting from '../../assets/svg/sidebarIcons/subtitleWriting.svg';
import SpeechEvaluation from '../../assets/svg/sidebarIcons/speechEvaluation.svg';
import PostProcess from '../../assets/svg/sidebarIcons/postprocess.svg';
import Translation from '../../assets/svg/sidebarIcons/translation.svg';

const iconMap = {
  "SpeechToText": SpeechToText,
  "PostProcessSTT": PostProcess,
  "TextToSpeech": TextToSpeech,
  "Translation": Translation,
  "GenerateSubtitles": SubtitleWriting,
  "PreprocessTTSStep": PreProcess,
  "SpeechCorrectnessEvaluation": SpeechEvaluation,
};
const edgeTypes = {
  floating: SimpleFloatingEdge,
};

let list = [];
let targetData;
let dataset = {}
let newNodesArray;

let id = 1;
let nodeId = 1
const getId = () => `${nodeId++}`;

const Canvas = () => {
  const reactFlowWrapper = useRef(null);
  const edgeUpdateSuccessful = useRef(true);
  const [nodes, setNodes] = useNodesState([]);
  const [edges, setEdges] = useEdgesState([]);
  const [reactFlowInstance, setReactFlowInstance] = useState(null);
  const [isProperty, setIsProperty] = useState(false);
  const [isNotification, setIsNotification] = useState(false);
  const [nodeDetails, setNodeDetails] = useState('');
  const [nodeBg, setNodeBg] = useState('');
  const [isNew, setIsNew] = useState('');
  const [isConnect, setIsConnect] = useState(false);
  const [openBrowseWindow, setOpenBrowseWindow] = useState(false);
  const [files, setFiles] = useState([]);
  const [publishAnchorEl, setPublishAnchorEl] = useState(null);
  const [openPopup, setOpenPopup] = useState(false);
  const [selectedStartNode, setSelectedStartNode] = useState('');
  const [selectedEndNode, setSelectedEndNode] = useState('');
  const [selectedSkipNodes, setSelectedSkipNodes] = useState([]);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [confirmSave, setConfirmSave] = useState(false);
  const [nodeToDelete, setNodeToDelete] = useState(null);
  const [clicked, setClicked] = useState(false);
  const [points, setPoints] = useState({ x: 0, y: 0, });
  const [selectedDeleteNode, setSelectedDeleteNode] = useState('');
  const [hitOrchestrator, setHitOrchestrator] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isConfirm, setIsConfirm] = useState(false);
  const navigate = useNavigate();

  if (sessionStorage.getItem('userName') === null) {
    navigate('/')
    toast.dismiss();
  }

  // for handle node click
  useEffect(() => {
    const handleClick = () => setClicked(false);
    window.addEventListener("click", handleClick);
    return () => {
      window.removeEventListener("click", handleClick);
    };

  }, []);


  const handleStartNodeSelect = (selectedOption) => {
    setSelectedStartNode(selectedOption);
  };

  const handleEndNodeSelect = (selectedOption) => {
    setSelectedEndNode(selectedOption);
  };

  const handleSkipNodeSelect = (selectedOptions) => {
    setSelectedSkipNodes(selectedOptions);
  };

  // set options for dropdown
  const nodeOptions = nodes.map((node, index) => ({
    index: index,
    value: node.id,
    label: node?.node?.Component?.StepName,
  }));

  let startNodeOptions = []

  // Skip node filter
  const getFilteredNodeOptions = () => {
    const startNodeValue = selectedStartNode ? selectedStartNode.value : null;
    const endNodeValue = selectedEndNode ? selectedEndNode.value : null;
    const skipNodeValues = selectedSkipNodes.map((option) => option.value);
    return nodeOptions.filter(
      (option) =>
        option.value !== startNodeValue &&
        option.value !== endNodeValue &&
        !skipNodeValues.includes(option.value)
    );
  };

  // Start node filter
  const getFilteredStartOptions = () => {
    const endNodeValue = selectedEndNode ? selectedEndNode.value : null;
    const skipNodeValues = selectedSkipNodes.map((option) => option.value);
    nodeOptions.map(item => {
      let data = edges.find(o => o.source === item.value)
      if (data !== undefined) {
        if (data.source === item.value) {
          startNodeOptions.push(item)
        }
      }
    })
    return startNodeOptions.filter(
      (option) =>
        option.value !== endNodeValue &&
        option.value !== selectedEndNode?.value &&
        !skipNodeValues.includes(option.value)
    );
  };

  // End node filter
  const getFilteredEndOptions = () => {
    const startNodeValue = selectedStartNode ? selectedStartNode.value : null;
    const skipNodeValues = selectedSkipNodes.map((option) => option.value);

    return nodeOptions.slice(1).filter(
      (option) =>
        option.value !== startNodeValue &&
        !skipNodeValues.includes(option.value)
    );
  };

  const endAndSkipOptions = getFilteredNodeOptions(true);

  // Submition of Start End Skip Nodes
  const handleSubmit = () => {
    const startNodeId = selectedStartNode ? selectedStartNode.value : null;
    const endNodeId = selectedEndNode ? selectedEndNode.value : null;
    const skipNodeIds = selectedSkipNodes.map((option) => option.value);
    const updatedNodes = nodes.map((node) => {
      let updatedNode = { ...node };
      if (node.id === startNodeId) {
        updatedNode.node.Component.nodeRole = 'Start';
      } else if (node.id === endNodeId) {
        updatedNode.node.Component.nodeRole = 'End';
      } else if (skipNodeIds.includes(node.id)) {
        updatedNode.node.Component.nodeRole = 'Skip';
      } else {
        updatedNode.node.Component.nodeRole = 'Normal';
      }
      return updatedNode;
    });
    setNodes(updatedNodes);
    handleClosePopup();
  };


  const handleNodeDoubleClick = () => {
    setNodeToDelete(selectedDeleteNode.node ? selectedDeleteNode.node : selectedDeleteNode);
    setConfirmDelete(true);
  };

  const handleConfirmDelete = () => {
    if (nodeToDelete) {
      deleteNode(nodeToDelete);
    }
    setConfirmDelete(false);
  };

  const handlePublishClick = (event) => {
    setPublishAnchorEl(!publishAnchorEl);
  };

  const handlePublishClose = () => {
    setPublishAnchorEl(null);
  };

  const handleExportAsJson = () => {
    exportAsJson();
    handlePublishClose();
  };

  const handleOpenPopup = () => {

    setOpenPopup(true);
    handlePublishClose();
  };

  const handleClosePopup = () => {
    setOpenPopup(false);
  };

  // Delete Node Function
  const deleteNode = () => {
    let stepIdToDelete = selectedDeleteNode.testId;
    const id = nodes.filter(o => o.id === stepIdToDelete)
    setNodes((prevNodes) => prevNodes.filter((node) => node.id !== id[0]?.id));
    setEdges((prevEdges) => prevEdges.filter((edge) => edge.source !== id[0]?.id && edge.target !== id[0]?.id));
    setIsProperty(false);
    if (selectedDeleteNode.Component.StepName === selectedStartNode?.label) {
      setSelectedStartNode(null)
    }
    if (selectedDeleteNode.Component.StepName === selectedEndNode?.label) {
      setSelectedEndNode(null)
    }
    let newDataSet = {}
    nodes.map(item => {
      item.node.Component.Inputs.map(e => {
        if (selectedDeleteNode?.Component?.Outputs?.length > 1) {
          if (e.value === selectedDeleteNode.Component.Outputs[1].value || e.value === selectedDeleteNode.Component.Outputs[0].value) {
            e.value = ''
            e.isBusy = false
          }
        }
        else {
          if (e.value === selectedDeleteNode.Component.Outputs[0].value) {
            e.value = ''
            e.isBusy = false
          }
        }
      })
      if (item.node.testId !== selectedDeleteNode.testId) {
        item.node.Component.Inputs.map(e => {
          newDataSet[e.label] = e.value;
        })
        item.node.Component.Outputs.map(e => {
          newDataSet[e.label] = e.value;
        })
      }
    })
    dataset = newDataSet;
    if (nodes.length === 0) {
      nodeId = 1
    }
  };

  // setting new nodes and its position
  useEffect(() => {
    newNodesArray = nodes
    nodes.sort((a, b) => {
      if (Math.abs(a.position.y - b.position.y) <= 10) {
        return a.position.x - b.position.x;
      }
      return a.position.y - b.position.y;
    });
  }, [nodes, edges]);

  // get data from project
  const getProjectData = async () => {
    setIsLoading(true)
    setNodes([])
    setEdges([]);
    nodeId = 1;
    const proData = await getProjectByID(sessionStorage.getItem("userId"), sessionStorage.getItem("projectId"))
    const parseData = proData?.data?.[0]?.project_json
    if (parseData?.length > 1) {
      nodeId = parseData.length - 2
      nodeId++
    }
    if (proData.status) {
      if (parseData.length > 0) {
        parseData?.forEach(function (item) {
          if (item?.node?.Component?.nodeRole === "Start") {
            setSelectedStartNode({
              value: item.id,
              label: item?.node?.Component?.StepName,
            })
          }
          if (item?.node?.Component?.nodeRole === "End") {
            setSelectedEndNode({
              value: item.id,
              label: item?.node?.Component?.StepName,
            })
          }
          if (item?.node?.Component?.nodeRole === "Skip") {
            setSelectedSkipNodes((nds) => nds.concat({
              value: item.id,
              label: item?.node?.Component?.StepName,
            }))

          }
          let nodesData = parseData.find(o => o?.node?.Component?.StepID === item?.node?.Component?.StepID && o?.id === item?.id)
          let renderData = {
            label: <div style={{ display: 'flex', alignItems: 'center' }}>
              <img style={{ backgroundColor: '#1976d2', height: '100%', padding: '7px' }} width="22px" src={iconMap[item?.node?.Component.StepID]} alt="Icon" />
              <span style={{ padding: '.5rem 1rem .5rem 3.5rem', width: '100%', marginLeft: '-2.6rem' }} onContextMenu={(e) => { e.preventDefault(); setClicked(true); setSelectedDeleteNode(item.node); setPoints({ x: e.pageX, y: e.pageY, }) }} onClick={(e) => onNodeClick(item?.node?.Component?.StepName, item.node.testId)}>{item?.node?.Component?.StepName}</span>
            </div>
          }
          if (!item.Dataset && !item.Edges) {
            setNodes((nds) => nds.concat({
              id: item.id,
              node: nodesData.node,
              position: { x: item.position.x, y: item.position.y },
              data: renderData
            }))
          }
          if (item.Edges) {
            item.Edges.forEach(function (edges) {
              setEdges((eds) => addEdge(edges, eds))
            })
          }
          if (item.Dataset) {
            dataset = item.Dataset
          }
          id = parseData.length - 1
        })
      }
    }
    setIsLoading(false)
  }

  // use for project data, blobdata, setting properties of nodes and connecting nodes
  useEffect(() => {
    getProjectData();
    getBlobData();
    setIsProperty(false)
    setIsConnect(false)
  }, [])


  // node connection
  const onConnect = (params) => {
    params.markerEnd = { type: MarkerType.ArrowClosed }
    let source = nodes.find(e => e.id === params.source)
    let target = nodes.find(e => e.id === params.target)
    params.sourceData = source
    params.targetData = target
    let isTrue = false, outputExtension;
    let output = source.node.Component.Outputs[0]
    outputExtension = output?.value?.split('.').pop();
    if (target.node.Component.Inputs.length > 1) {
      list = target.node.Component.Inputs
      setIsConnect(true)
      targetData = { output, target, params, outputExtension }
    } else {
      target.node.Component.Inputs?.forEach(input => {
        if (input.isBusy) {
          toast("Port Already busy", { position: "top-right", hideProgressBar: true });
          isTrue = true;
        } else {
          const allowExtension = input?.allowed?.split('|')
          allowExtension.forEach((item) => {
            if (outputExtension === item) {
              setEdges((eds) => addEdge(params, eds, item))
              const selectedNodeUpdate = nodes.find(o => o.id === target.id)
              selectedNodeUpdate.node.Component.Inputs[0].value = output.value
              selectedNodeUpdate.node.Component.Inputs[0].isBusy = true
              let keys = Object.keys(dataset);
              const check = keys.find(o => o === output.label)
              dataset[check] = output.value
              isTrue = true;
            }
          })
        }
        if (!isTrue) {
          toast("This connection cannot be established as the Components are incompatible with each other.", { position: "top-right", hideProgressBar: true });
        }
      })
    }
  }


  // on click of connect
  const onClickforConnect = (data) => {
    let isTrue = false;
    if (!data.isBusy) {
      targetData.params.connectedLable = data.label
      targetData.target.node.Component.Inputs?.forEach(input => {
        const allowExtension = input?.allowed?.split('|')
        allowExtension.forEach((item) => {
          if (targetData.outputExtension === item) {
            setEdges((eds) => addEdge(targetData.params, eds, item))
            const selectedNodeUpdate = nodes.find(o => o.node.Component.StepID === targetData.target.node.Component.StepID)
            selectedNodeUpdate.node.Component.Inputs.map(inputs => {
              if (inputs.label === data.label) {
                inputs.value = targetData.output.value
                inputs.isBusy = true
                let keys = Object.keys(dataset);
                const check = keys.find(o => o === data.label)
                dataset[check] = targetData.output.value
              }
            })
            isTrue = true;
          }
        })
        setIsConnect(false)
      })
      if (!isTrue) {
        toast("This connection cannot be established as the Components are incompatible with each other.", { position: "top-right", hideProgressBar: true });
      }
    } else {
      toast("Port Already busy", { position: "top-right", hideProgressBar: true });
      isTrue = true;
    }
  }

  const onNodesChange = (changes) => {
    setNodes((nds) => applyNodeChanges(changes, nds))
  }

  const onUpdateOrder = (val, name) => {
    if (nodeDetails.StepName === name) {
      nodeDetails.StepOrder = val;
    }
  }

  const onEdgesChange = (changes) => {
    setEdges((eds) => applyEdgeChanges(changes, eds))
  }

  const onEdgeUpdateStart = () => {
    edgeUpdateSuccessful.current = false;
  }

  const onEdgeUpdate = (oldEdge, newConnection) => {
    edgeUpdateSuccessful.current = true;
    setEdges((els) => updateEdge(oldEdge, newConnection, els));
  }

  // Updating edge of nodes
  const onEdgeUpdateEnd = (_, edge) => {
    if (!edgeUpdateSuccessful.current) {
      setEdges((eds) => eds.filter((e) => e.id !== edge.id));
      nodes.filter(e => {
        if (e.id === edge.target) {
          if (e.node.Component.Inputs.length > 1) {
            e.node.Component.Inputs.map(item => {
              if (item.label === edge.connectedLable) {
                item.value = ''
                item.isBusy = false
              }
            })
          } else {
            e.node.Component.Inputs[0].value = ''
            e.node.Component.Inputs[0].isBusy = false
          }
        }
      })
    }
    edgeUpdateSuccessful.current = true;
  }


  const onDragOver = useCallback((event) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = 'move';
  }, []);


  // On drop of node
  const onDrop = useCallback(
    (event) => {
      event.preventDefault();
      const reactFlowBounds = reactFlowWrapper.current.getBoundingClientRect();
      const node = JSON.parse(event.dataTransfer.getData('node'));
      node.Component["nodeRole"] = 'Normal'
      if (typeof node.Component.StepName === 'undefined' || !node.Component.StepName) {
        return;
      }

      const position = reactFlowInstance.project({
        x: event.clientX - reactFlowBounds.left,
        y: event.clientY - reactFlowBounds.top,
      });

      let newId = getId();
      node['testId'] = `${newId}`

      const newNode = {
        id: `${newId}`,
        node,
        position,
        data: {
          label:
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <img style={{ backgroundColor: '#1976d2', height: '100%', padding: '7px' }} width="22px" src={iconMap[node.Component.StepID]} alt="Icon" />
              <span style={{ padding: '.5rem 1rem .5rem 3.5rem', width: '100%', marginLeft: '-2.6rem' }} onContextMenu={(e) => { e.preventDefault(); setClicked(true); setSelectedDeleteNode(node); setPoints({ x: e.pageX, y: e.pageY, }); }} onClick={(e) => onNodeClick(node.Component.StepName, node.testId)}>{node.Component.StepName}</span>
            </div>
        }
      };
      setNodes((nds) => nds.concat(newNode));
    },
    [setNodes, reactFlowInstance]
  );
  const handleClick = () => {
    setIsProperty(false)
  };

  // on click of node opens property and set details of node
  const onNodeClick = (e, id) => {
    setIsNotification(false)
    if (e !== '') {
      const selectedNode = newNodesArray.filter(node => node.node.Component.StepName === e && node.id === id)
      let nodeObj = selectedNode[0]
      setNodeDetails(nodeObj)
      setIsProperty(true);
    }
  }


  // Json Updation
  const updatedJSON = (newJSON) => {
    const selectedNodeUpdate = nodes.find(o => o.node.Component.StepID === newJSON.node.Component.StepID && o.id === newJSON.id)
    selectedNodeUpdate.node = newJSON.node
  }

  let nothingToPublish = false
  // Export function to export file in Json format
  const exportAsJson = async () => {
    setIsLoading(true)
    let isAllConnected = true, isValid = true, findEdges
    nodes.map(item => {
      item?.node?.Component?.Config?.Control?.map(con => {
        if (con?.error) {
          isValid = false
        }
      })
      item?.node?.Component?.Config?.Subsection?.map(con => {
        con?.Control?.map((item, index) => {
          if (item?.error) {
            isValid = false
          }
        })
      })
      if (item.id) {
        findEdges = edges.filter(edge => edge.target === item.id || edges[0].source === item.id)
        if (findEdges.length === 0) {
          isAllConnected = false
        }
      }
    })

    if (isAllConnected) {
      if (isValid) {
        onProjectSave(true);
        if (nothingToPublish === false) {
          const uid = sessionStorage.getItem('userId')
          const pid = sessionStorage.getItem('projectId')
          setTimeout(async () => {
            setIsLoading(true)
            const exportJson = await publishJSON(uid, pid)
            if (exportJson.status) {
              let newId = 1
              exportJson.data.Pipeline.PipelineSteps.map(item => {
                item.StepOrder = JSON.stringify(newId)
                newId++
              })
              const data = exportJson.data
              const fileName = data.Pipeline.Name;
              const newData = new Blob([JSON.stringify(data)], { type: "text/json" });
              const jsonURL = window.URL.createObjectURL(newData);
              const link = document.createElement("a");
              document.body.appendChild(link);
              link.href = jsonURL;
              link.setAttribute("download", fileName);
              link.click();
              document.body.removeChild(link);
              setIsLoading(false)
              toast("File Saved and download successfully", { position: "top-right", hideProgressBar: true });
            } else {
              setIsLoading(false)
              toast(exportJson.message, { position: "top-right", hideProgressBar: true });
            }
          }, 5000);
        }
      } else {
        setIsLoading(false)
        toast("Please Select All The Mandatory Fields", { position: "top-right", hideProgressBar: true });
      }
    } else {
      setIsLoading(false)
      toast("Please Connect All nodes", { position: "top-right", hideProgressBar: true });
    }
  }

  // This use for setNodes
  useEffect(() => {
    setNodes((nds) =>
      nds.map((node) => {
        if (node?.node?.Component?.StepName === nodeDetails?.Component?.StepName) {
          if (!node.state) {
            node.state = nodeBg === '#acf0a3' ? 'Start' : '' || nodeBg === '#f7adba' ? 'End' : '' || nodeBg === '#ddded9' ? 'Skip' : ''
            node.style = { ...node.style, backgroundColor: nodeBg, color: nodeBg === '#ddded9' || nodeBg === '' ? 'black' : 'white' };
          }
        }
        return node;
      })
    );
  }, [nodeBg, nodeDetails, setNodes]);


  // Save Project 
  const onProjectSave = async (check) => {
    setIsLoading(true)
    let isValid = true, isAllConnected = true, findEdges, newDataSet = {};
    nodes?.map(item => {
      item.node.Component?.Inputs?.map(e => {
        if (e.value !== '') {
          newDataSet[e.label] = e.value;
        }
      })
      item.node.Component?.Outputs?.map(e => {
        if (e.value !== '') {
          newDataSet[e.label] = e.value;
        }
      })
      if (item.node.Component.StepID === "TextToSpeech") {
        newDataSet["TTS_WAV_FLDR"] = files[0]?.name
      }

      item?.node?.Component?.Config?.Control?.map(con => {
        if (con?.error) {
          isValid = false
        }
      })
      item?.node?.Component?.Config?.Subsection?.map(con => {
        con?.Control?.map((item, index) => {
          if (item?.error) {
            isValid = false
          }
        })
      })

      if (item.id) {
        findEdges = edges.filter(edge => edge.target === item.id || edges[0].source === item.id)

        if (findEdges.length === 0) {
          isAllConnected = false
        }
      }
    })
    if (isAllConnected) {
      if (isValid) {
        if (nodes.length > 1) {
          nodes.push({ "Dataset": newDataSet });
          nodes.push({ "Edges": edges })
          const uid = sessionStorage.getItem('userId')
          const pid = sessionStorage.getItem('projectId')
          const afterSave = await saveProject(uid, pid, nodes)
          if (afterSave.status) {
            setHitOrchestrator(true)
            if (check !== true) {
              setIsLoading(false)
              toast(afterSave.message, { position: "top-right", hideProgressBar: true });
            }
          }
          nodes.splice(nodes.findIndex(a => a.dataset), 1)
          nodes.splice(nodes.findIndex(a => a.Edges), 1)
        } else {
          setIsLoading(false)
          nothingToPublish = true
          toast("Atleast One component Drag on Canvas", { position: "top-right", hideProgressBar: true });
        }
      } else {
        setIsLoading(false)
        toast("Please Select All The Mandatory Fields", { position: "top-right", hideProgressBar: true });
      }
    } else {
      setIsLoading(false)
      toast("Please Connect All nodes", { position: "top-right", hideProgressBar: true });
    }
    setIsConfirm(false)
  }

  const handleDialogOpen = () => {
    toast.dismiss();
    setConfirmSave(true)

  };

  // Handle Save Confirmation
  const handleConfirmSave = () => {
    onProjectSave();
    setTimeout(() => {
      toast.dismiss();
      setIsNew(true)
    }, 3000);

  }

  // for closing dialog box
  const handleDialogClose = () => {
    setIsNew(false);
    getProjectData();
    setIsProperty(false)
    setSelectedStartNode(null);
    setSelectedEndNode(null);
    setSelectedSkipNodes([]);
  };


  // Confirmation for Autoskip
  const handleConfirmation = () => {
    nodes.sort((a, b) => {
      if (Math.abs(a.position.y - b.position.y) <= 10) {
        return a.position.x - b.position.x;
      }
      return a.position.y - b.position.y;
    });
    nodes.map((item, index) => {
      item.node['rearrangeId'] = `${index + 1}`
    })
    nodes.map((item, index) => {
      if (item.node.Component.nodeRole === "Start") {
        nodes.map((newItem, ind) => {
          if (newItem.node.rearrangeId !== item.node.rearrangeId && newItem.node.rearrangeId < item.node.rearrangeId && ind !== index && ind < index) {
            newItem.node.Component.nodeRole = 'autoSkip'
          }
        })
      }
      if (item.node.Component.nodeRole === "End") {
        nodes.map((newItem, ind) => {
          if (newItem.node.rearrangeId > item.node.rearrangeId) {
            newItem.node.Component.nodeRole = 'autoSkip'
          }
        })
      }

    })

    const find = nodes.filter(o => o.node.Component.nodeRole === "autoSkip")
    if (find.length > 0) {
      setIsConfirm(true);
    } else {
      handleExportAsJson()

    }

  };

  // fetching data from blob storage
  const getBlobData = async () => {
    setIsLoading(true)
    const blobData = await createBlobContainer(sessionStorage.getItem('userName'))
    if (blobData.status) {
      setFiles(blobData.folderContents)
      setIsLoading(false)
    }
  }


  // browse file
  const fetchFiles = async () => {
    if (files.length > 0) {
      setOpenBrowseWindow(true)
    }
    else {
      setIsLoading(false)
      toast("Something went wrong!", { position: "top-right", hideProgressBar: true });
    }
  };

  const onBrowseFileClicked = (item) => {
    dataset.WavFile = item.name
    let binaryNode = nodes.find(o => o.node.Component.Inputs[0].isBinary)
    binaryNode.node.Component.Inputs[0].value = item.name
    setOpenBrowseWindow(false)
  }

  return (
    <div style={{ height: '100vh', flexDirection: 'row', display: 'flex' }}>
      <div className="loader-container" style={{ display: isLoading ? 'flex' : 'none' }}>
        <div className="spinner"></div>
      </div>
      <Header setIsNotification={setIsNotification} isNotification={isNotification} />
      {isNew && (
        <ResponsiveDialog open={isNew} setConfirmSave={setConfirmSave} onClose={handleDialogClose} />
      )}
      <ToastContainer style={{ marginTop: '1.5rem' }} />
      <ReactFlowProvider>
        <div className="dndflow">
          <Sidebar />
          <div style={{ flexDirection: 'column', width: '100%' }}>
            <div className='header'>
              <div className='pointer' onClick={handleDialogOpen}>
                <img width='16px' src={AddNew} alt="Icon" />
                <span className='text' >New</span>
              </div>
              <span className='divide'>|</span>
              <div className='pointer' onClick={onProjectSave}>
                <img width='16px' src={Save} alt="Icon" />
                <span className='text'>Save</span>
              </div>
              <span className='divide'>|</span>
              <div className='pointer'>
                <img width='16px' src={SaveAs} alt="Icon" />
                <span className='text'>Save As</span>
              </div>
              <span className='divide'>|</span>
              <div className="dropdown" onMouseLeave={() => setPublishAnchorEl(null)}>
                <div className='pointer' onClick={handlePublishClick}>
                  <img width='16px' src={Upload} alt="Icon" />
                  <span className='text'>Publish</span>
                </div>
                <div style={{ display: publishAnchorEl ? 'block' : 'none' }} className="dropdown-content">
                  <div onClick={handleConfirmation}>Publish JSON</div>
                  <div onClick={handleOpenPopup}>Set Publish</div>
                </div>
              </div>
            </div>
            <div className="reactflow-wrapper" ref={reactFlowWrapper}>
              <ReactFlow
                nodes={nodes}
                edges={edges}
                edgeTypes={edgeTypes}
                onNodesChange={onNodesChange}
                onEdgesChange={onEdgesChange}
                onEdgeUpdate={onEdgeUpdate}
                onEdgeUpdateStart={onEdgeUpdateStart}
                onEdgeUpdateEnd={onEdgeUpdateEnd}
                onConnect={onConnect}
                onInit={setReactFlowInstance}
                onDrop={onDrop}
                onDragOver={onDragOver}
                style={{ justifyContent: 'right', display: 'flex' }} />

              <div className="popup" style={{ display: confirmDelete ? 'flex' : 'none' }}>
                <div className="popup-content">
                  <div className='headerStyle'>Confirmation</div>
                  <div>Are you sure you want to delete this node?</div>
                  <div style={{ justifyContent: 'flex-end', display: 'flex', marginTop: '10px' }}>
                    <button
                      className='button'
                      style={{
                        backgroundColor: "#0066FF",
                        border: 'none',
                        marginRight: "14px",
                        width: "80px",
                      }}
                      variant="contained"
                      onClick={handleConfirmDelete} >
                      Delete
                    </button>
                    <button
                      className='button'
                      style={{
                        border: '.2px solid black',
                        width: "80px",
                        color: "black",
                      }}
                      onClick={() => setConfirmDelete(false)}
                      variant="contained" >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>

              <div className="popup" style={{ display: isConfirm ? 'flex' : 'none' }}>
                <div className="popup-content">
                  <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <img onClick={() => setIsConfirm(false)} style={{ cursor: 'pointer' }} width='24px' src={Close} alt="Icon" />
                  </div>
                  <div className='headerStyle' style={{ marginTop: '-25px' }}>Confirmation</div>
                  <div>This Nodes will Skip automatically</div>
                  {nodes?.map((item, index) => {
                    if (item?.node?.Component?.nodeRole === "autoSkip") {
                      return (
                        <li key={index}>{item?.node?.Component?.StepName}</li>
                      )
                    }
                  })}
                  <div style={{ justifyContent: 'center', display: 'flex', marginTop: '10px' }}>
                    <button
                      className='button'
                      style={{
                        border: 'none',
                        width: "80px",
                        backgroundColor: "#0066FF",
                      }}
                      onClick={handleExportAsJson}
                      variant="contained">
                      Done
                    </button>
                  </div>
                </div>
              </div>

              <div className="popup" style={{ display: confirmSave ? 'flex' : 'none' }}>
                <div className="popup-content">
                  <div className='headerStyle'>Confirmation</div>
                  <div>Do you Want to Save This Project?</div>
                  <div style={{ justifyContent: 'flex-end', display: 'flex', marginTop: '10px' }}>
                    <button
                      className='button'
                      style={{
                        backgroundColor: "#0066FF",
                        border: 'none',
                        marginRight: "14px",
                        width: "80px",
                      }}
                      variant="contained"
                      onClick={handleConfirmSave}>
                      Save
                    </button>
                    <button
                      className='button'
                      style={{
                        border: '.2px solid black',
                        width: "80px",
                        color: "black",
                      }}
                      onClick={() => setConfirmSave(false)}
                      variant="contained">
                      Cancel
                    </button>
                  </div>
                </div>
              </div>

              <div className='popup' style={{ display: openBrowseWindow ? 'flex' : 'none' }}>
                <div className="popup-content">
                  <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <img onClick={() => setOpenBrowseWindow(false)} style={{ cursor: 'pointer' }} width='24px' src={Close} alt="Icon" />
                  </div>
                  <div style={{ backgroundColor: 'white', textAlign: 'center' }}>
                    <h4>Please establish your connection</h4>
                  </div>
                  {files.map((item, index) => (
                    <div key={index} style={{ fontSize: '12px', cursor: 'pointer', margin: '1rem', justifyContent: 'space-between', display: 'flex', alignItems: 'center' }} onClick={() => onBrowseFileClicked(item)}>
                      {index + 1 + '. ' + item.name}
                      <img width='20px' src={Fork} alt="Icon" />
                    </div>
                  ))}
                </div>
              </div>

              <div className='popup' style={{ display: isConnect ? 'flex' : 'none' }}>
                <div className="popup-content">
                  <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <img onClick={() => setIsConnect(false)} style={{ cursor: 'pointer' }} width='24px' src={Close} alt="Icon" />
                  </div>
                  <div style={{ backgroundColor: 'white', textAlign: 'center' }}>
                    <h4>Please establish your connection</h4>
                  </div>
                  {list.map((item, index) => (
                    <div key={index} style={{ cursor: 'pointer', margin: '1rem', justifyContent: 'space-between', display: 'flex', alignItems: 'center' }} onClick={() => onClickforConnect(item)}>
                      {index + 1 + '. ' + item.label}
                      {item.isBusy ? <img title='Port Busy' width='20px' src={Busy} alt="Icon" /> : <img title='Port Free' width='20px' src={Fork} alt="Icon" />}
                    </div>
                  ))}
                </div>
              </div>

              <div className='popup' style={{ display: openPopup ? 'flex' : 'none' }}>
                <div className="popup-content" style={{ width: '300px' }}>
                  <div>
                    <label  >Select Start Node:</label>
                    <Select
                      className='SelectNodeLabel'
                      value={selectedStartNode}
                      onChange={handleStartNodeSelect}
                      options={getFilteredStartOptions()}
                      isClearable
                      isSearchable />
                  </div>

                  <div>
                    <label>Select End Node:</label>

                    <Select
                      className='SelectNodeLabel'
                      value={selectedEndNode}
                      onChange={handleEndNodeSelect}
                      options={getFilteredEndOptions()}
                      isClearable
                      isSearchable />
                  </div>

                  <div>
                    <label>Select Skip Nodes:</label>
                    <Select
                      className='SelectNodeLabel'
                      value={selectedSkipNodes}
                      onChange={handleSkipNodeSelect}
                      options={endAndSkipOptions}
                      isMulti
                      isSearchable />
                  </div>
                  <div style={{ float: 'right', marginTop: '15px' }}>
                    <button
                      className='button'
                      style={{
                        backgroundColor: "#0066FF",
                        border: 'none',
                        marginRight: "14px",
                        width: "80px",
                      }}
                      variant="contained"
                      onClick={handleSubmit} >
                      Submit
                    </button>
                    <button
                      className='button'
                      style={{
                        border: '.2px solid black',
                        width: "80px",
                        color: "black",
                      }}
                      onClick={handleClosePopup}
                      variant="contained">
                      Cancel
                    </button>
                  </div>
                </div>
              </div>

            </div>
          </div>
          <Properties fetchFiles={fetchFiles} nodes={nodes} onUpdateOrder={onUpdateOrder} updatedJSON={updatedJSON} display={isProperty} data={nodeDetails} setIsProperty={handleClick}  />
          <NotificationBar display={isNotification} setIsNotification={setIsNotification} setHitOrchestrator={setHitOrchestrator} hitOrchestratorcall={hitOrchestrator} />
        </div>

        {clicked && (
          <ContextMenu top={points.y} left={points.x}>
            <ul>
              <li onClick={handleNodeDoubleClick}>Delete</li>
            </ul>
          </ContextMenu>
        )}

      </ReactFlowProvider>

    </div>
  );
};

export default Canvas;