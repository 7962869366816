import * as React from 'react';
import { useNavigate } from "react-router-dom"
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { createProject } from '../Services/Services';
import Close from '../assets/svg/x.svg';


export default function ResponsiveDialog({ open, onClose, setConfirmSave, from }) {
  const [projectName, setProjectName] = React.useState('');
  const navigate = useNavigate();
  const handleClose = () => {
    onClose(false);
if(from!== 'allProject'){
    setConfirmSave(false)
  }
  };

  // Function to create new project popup Window
  const handleCreateProject = async () => {
    if (projectName) {
      const data = await createProject(sessionStorage.getItem('userId'), projectName)
    if (data.status) {
        sessionStorage.setItem("projectId", data.data.projectID)
        sessionStorage.setItem("userId", data.data.userID)
        sessionStorage.setItem('ProjectName', projectName);
        sessionStorage.setItem('create', true);
        navigate('/canvas');
      } else {
        toast(data.message, { position: "top-right", hideProgressBar: true });
      }
      setProjectName('');
      handleClose();
    } else {
      toast('Please choose your project name', { position: "top-right", hideProgressBar: true });
    }
  };

  return open ? (
    <div
    className="popup"
      >
        <div
         className='popup-content'
         style={{height:'30%',}}
        >
          <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", color: "black", marginBottom: "23px", fontSize: "16px", fontWeight: 500 }}>
            <b>Project</b>
            <img onClick={handleClose} width='24px' src={Close} alt="Icon" />
          </div>
        
            <div
              style={{color: "black",marginBottom: "23px",fontSize: "14px",fontWeight: 500, }}>
              Choose your project name
            </div>
            <input
              style={{ fontSize: "12px", width: "100%"}}
              type="text"
              placeholder="Enter Project Name"
              required
              value={projectName}
              onChange={(e) => setProjectName(e.target.value)}
            />
          
          <div style={{ float: 'right', marginTop: '20px' }}>
            <button
              className='button'
              style={{    backgroundColor: "#0066FF",    border: 'none',    marginRight: "14px",    width: "80px",  }}  
              onClick={handleCreateProject}>
              Create
            </button>
            <button
              className='button'
              style={{
                border: '.2px solid black',
                width: "80px",
                color: "black",
              }}
              onClick={handleClose}
            >
              Cancel
            </button>
          </div>
        </div>
        </div>
  ) : null;
}
