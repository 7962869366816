import React, { Component } from "react";
import DropdownSelect from "react-dropdown-select";

class DropdownCheckboxControl extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedOptions: this.props.json.selected,
    };
  }

  handleCheckboxChange = (values) => {
    const { json, onChangeValues } = this.props;
    this.setState({ selectedOptions: values });
    json.selected = values;
    if (values.length > 0) {
      delete json.error;
    }
    onChangeValues();
  };

  render() {
    const { json } = this.props;
    const { selectedOptions } = this.state;

    return (
      <>
        <div className="label">
          <label>
            {json.label}
            {json.mandatory === "true" ? <span className="mandatory">*</span> : ""}
            :
          </label>
        </div>
        <div>
          <DropdownSelect
            options={json.options}
            values={selectedOptions}
            onChange={this.handleCheckboxChange}
            multi
            placeholder="Select options..."
          />
        </div>
      </>
    );
  }
}

export default DropdownCheckboxControl;
