import React, { useState } from "react";

const CheckboxControl = ({ json }) => {
  const [selectedOptions, setSelectedOptions] = useState(
    json.selected
  );

  const handleCheckboxChange = (option) => {
    const updatedOptions = selectedOptions.includes(option)
      ? selectedOptions.filter((item) => item !== option)
      : [...selectedOptions, option];
    setSelectedOptions(updatedOptions);
  };

  return (
    <div style={{marginBottom:'10px',display:'flex',justifyContent:'space-between',}}>
      <label>{json.label}:</label>
      {json.options.map((option, index) => (
        <>
          <input
            style={{width:'50%'}}
            key={index}
            type="checkbox"
            checked={selectedOptions.includes(option)}
            onChange={() => handleCheckboxChange(option)}
          />
          <span>{option}</span>
        </>
      ))}
    </div>
  );
};

export default CheckboxControl;
