import React, { Component } from "react";
class TextInputControl extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showError: false,
    };
  }

  handleInputChange = (event) => {
    const { json, onChangeValues } = this.props;
    const val = event.target.value;
    let error = "";

    if (val !== "") {
      if (!this.isValidInput(val)) {
        error = "Spaces and special characters are invalid";
        this.setState({ showError: true });
      } else {
        this.setState({ showError: false });
      }
    } else {
      this.setState({ showError: false });
    }

    json.text = val;
    json.error = error;

    onChangeValues();
  };

  isValidInput = (input) => {
    const alphanumericRegex = /^[a-zA-Z0-9/]*$/;
    return alphanumericRegex.test(input);
  };

  render() {
    const { json } = this.props;
    const { showError } = this.state;

    return (
      <>
        <div className="label">
          <label>
            {json.label}
            {json.mandatory === "true" ? <span className="mandatory">*</span> : ""}
            :
          </label>
          {showError && (
            <span style={{ color: "red", paddingLeft: "10px", fontSize: "0.7rem" }}>
              {json.error}
            </span>
          )}
        </div>
        <div>
          <input
            className="textbox"
            type="text"
            defaultValue={json.text}
            onChange={this.handleInputChange}
            placeholder="Enter Text here"
          />
        </div>
      </>
    );
  }
}

export default TextInputControl;
