import React, { Component } from 'react'
import CheckboxControl from './controlers/CheckbocControl'
import URIController from './controlers/URIControl'
import RadioButtonControl from './controlers/RadiobuttonControl'
import NumericInputControl from './controlers/NumericControl'
import TextInputControl from './controlers/TextControl'
import LocaleControl from './controlers/LocaleControl'
import RangeControl from './controlers/RangeControl'
import DropdownControl from './controlers/DropdownControl'
import BooleanControl from './controlers/BooleanControl'
import GroupControl from './controlers/GroupControl'
import DropdownCheckboxControl from './controlers/DropdownCheckboxControl'
import Close from '../../assets/svg/x.svg';
import './controlers/controllers.css'

export default class Property extends Component {
  constructor(props) {
    super(props)
    this.state = {
      inputValue: ''
    }
  }

  onChangeValues = () => {
    this.props.updatedJSON(this.state.inputValue);
  }

  componentDidUpdate(prevProps) {
    if (prevProps?.data?.node?.testId !== this.props?.data?.node?.testId) {
      this.setState({ inputValue: this.props.data })
      this.render();
    }
  }

  handleInputChange = (e, item) => {
    let newValue = null, newValue1 = null;
    newValue = this.props.data?.node?.Component?.Inputs.find(o => {
      if (o.label === item.label) {
        o.value = e.target.value
      }
    })
    newValue1 = this.props.data?.node?.Component?.Outputs.find(o => {
      if (o.label === item.label) {
        o.value = e.target.value
      }
    })
    this.onChangeValues()
  };

  render() {
    const { display, setIsProperty, data, fetchFiles } = this.props
    const { inputValue } = this.state;
    return (
      <div className="property" style={{ display: display ? 'block' : 'none', fontFamily: ' "Segoe UI", "Segoe UI Web (West European)", "Segoe UI", -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif', fontSize: '15px' }}>
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <div>
            <label title="Close">
              <img onClick={() => setIsProperty(false)} style={{ cursor: 'pointer' }} width='24px' src={Close} alt="Icon" />
            </label>
          </div>
        </div>
        <br></br>
        <div className="input-box" >
          <label><b>Inputs</b></label><br />
          {inputValue?.node?.Component?.Inputs ?
            inputValue.node.Component.Inputs.map((item, index) => {
              return (
                <div className='label' key={Math.random()}>
                  <label>{item.label} : </label><br />
                  {item.isBinary === true ?
                    <div className="example">
                      <input className='textbox' type="text" defaultValue={item.value} placeholder="Search.." name="search" />
                      <button style={{ height: '32px' }} type="submit" onClick={fetchFiles}>...</button>
                    </div>
                    :
                    <input className='textbox' type="text" defaultValue={item.value} onChange={(e) => this.handleInputChange(e, item)} />
                  }
                  <br />
                </div>
              )
            }) : <></>
          }
          <br />
        </div>
        <hr />
        <label><b>Properties</b></label><br />
        <label>Step Name : {data?.node?.Component?.StepName}</label><br />
        <label>Step ID : {data?.node?.Component?.StepID}</label><br /><br />
        <div style={{ justifyContent: 'space-between', display: 'flex', marginTop: '-10px' }}>

        </div>
        <hr />
        <label style={{ marginBottom: '10px' }}><b>StepConfig:</b></label ><br />
        {inputValue?.node?.Component?.Config?.Control ?
          inputValue.node.Component.Config.Control.map((item, index) => {
            if (item.mandatory === 'true') {
              if (item?.text === '' || item.selected === '' || item.selected?.length < 1) {
                item.error = true
              }
            }
            return (
              <div className="input-box" key={index}>
                {item && item.type === "Checkbox" ?
                  <CheckboxControl onChangeValues={this.onChangeValues} json={item} />
                  : item && item.type === "URI" ?
                    <URIController onChangeValues={this.onChangeValues} json={item} />
                    : item && item.type === "RadioButton" ?
                      <RadioButtonControl onChangeValues={this.onChangeValues} json={item} />
                      : item && item.type === "Numeric" ?
                        <NumericInputControl onChangeValues={this.onChangeValues} json={item} />
                        : item && item.type === "Text" ?
                          <TextInputControl onChangeValues={this.onChangeValues} json={item} />
                          : item && item.type === "Locale" ?
                            <LocaleControl onChangeValues={this.onChangeValues} json={item} />
                            : item && item.type === "Range" ?
                              <RangeControl onChangeValues={this.onChangeValues} json={item} />
                              : item && item.type === "Dropdown" ?
                                <DropdownControl onChangeValues={this.onChangeValues} json={item} />
                                : item && item.type === "Boolean" ?
                                  <BooleanControl onChangeValues={this.onChangeValues} json={item} />
                                  : item && item.type === "Group" ?
                                    <GroupControl onChangeValues={this.onChangeValues} json={item} />
                                    : item && item.type === "DropdownCheckboxControl" ?
                                      <DropdownCheckboxControl onChangeValues={this.onChangeValues} json={item} />
                                      : ''
                }
              </div>
            )
          }) : <></>
        }
        {inputValue?.node?.Component?.Config?.Subsection ?
          inputValue.node.Component.Config.Subsection.map((item, index) => {
            return (
              <div style={{ marginTop: '10px' }} key={index}>
                <label><b>{item.label}:</b></label><br />
                {item.Control ? item.Control.map((newitem, index) => {
                  if (newitem.mandatory === 'true') {
                    if (newitem?.text === '' || newitem.selected === '' || newitem.selected?.length < 1) {
                      newitem.error = true
                    }
                  }
                  return (
                    <div className="input-box" key={index}>
                      {newitem && newitem.type === "Checkbox" ?
                        <CheckboxControl onChangeValues={this.onChangeValues} json={newitem} />
                        : newitem && newitem.type === "URI" ?
                          <URIController onChangeValues={this.onChangeValues} json={newitem} />
                          : newitem && newitem.type === "RadioButton" ?
                            <RadioButtonControl onChangeValues={this.onChangeValues} json={newitem} />
                            : newitem && newitem.type === "Numeric" ?
                              <NumericInputControl onChangeValues={this.onChangeValues} json={newitem} />
                              : newitem && newitem.type === "Text" ?
                                <TextInputControl onChangeValues={this.onChangeValues} json={newitem} />
                                : newitem && newitem.type === "Locale" ?
                                  <LocaleControl onChangeValues={this.onChangeValues} json={newitem} />
                                  : newitem && newitem.type === "Range" ?
                                    <RangeControl onChangeValues={this.onChangeValues} json={newitem} />
                                    : newitem && newitem.type === "Dropdown" ?
                                      <DropdownControl onChangeValues={this.onChangeValues} json={newitem} />
                                      : newitem && newitem.type === "Boolean" ?
                                        <BooleanControl onChangeValues={this.onChangeValues} json={newitem} />
                                        : newitem && newitem.type === "Group" ?
                                          <GroupControl onChangeValues={this.onChangeValues} json={newitem} />
                                          : newitem && newitem.type === "DropdownCheckboxControl" ?
                                            <DropdownCheckboxControl onChangeValues={this.onChangeValues} json={newitem} />
                                            : ''
                      }
                    </div>
                  )
                }) : <></>}
                {item?.Speakers ? item.Speakers.map((newitem, index) => {
                  if (newitem.mandatory === 'true') {
                    if (newitem?.text === '' || newitem.selected === '' || newitem.selected?.length < 1) {
                      newitem.error = true
                    }
                  }
                  return (
                    <div className="input-box" key={index}>
                      {newitem && newitem.type === "Checkbox" ?
                        <CheckboxControl onChangeValues={this.onChangeValues} json={newitem} />
                        : newitem && newitem.type === "URI" ?
                          <URIController onChangeValues={this.onChangeValues} json={newitem} />
                          : newitem && newitem.type === "RadioButton" ?
                            <RadioButtonControl onChangeValues={this.onChangeValues} json={newitem} />
                            : newitem && newitem.type === "Numeric" ?
                              <NumericInputControl onChangeValues={this.onChangeValues} json={newitem} />
                              : newitem && newitem.type === "Text" ?
                                <TextInputControl onChangeValues={this.onChangeValues} json={newitem} />
                                : newitem && newitem.type === "Locale" ?
                                  <LocaleControl onChangeValues={this.onChangeValues} json={newitem} />
                                  : newitem && newitem.type === "Range" ?
                                    <RangeControl onChangeValues={this.onChangeValues} json={newitem} />
                                    : newitem && newitem.type === "Dropdown" ?
                                      <DropdownControl onChangeValues={this.onChangeValues} json={newitem} />
                                      : newitem && newitem.type === "Boolean" ?
                                        <BooleanControl onChangeValues={this.onChangeValues} json={newitem} />
                                        : newitem && newitem.type === "Group" ?
                                          <GroupControl onChangeValues={this.onChangeValues} json={newitem} />
                                          : newitem && newitem.type === "DropdownCheckboxControl" ?
                                            <DropdownCheckboxControl onChangeValues={this.onChangeValues} json={newitem} />
                                            : ''
                      }
                    </div>
                  )
                }) : <></>}
              </div>
            )
          }) : <></>
        }

        <hr style={{ marginTop: '15px' }} />
        <div className="input-box">
          <label style={{ marginBottom: '20px' }}><b>Outputs</b></label><br />
          {inputValue?.node?.Component?.Outputs ?
            inputValue.node.Component.Outputs.map((item, index) => {
              return (
                <div className='label' key={Math.random()}>
                  <label>{item.label} : </label><br />
                  <input type="text" className='textbox' defaultValue={item.value} onChange={(e) => this.handleInputChange(e, item)} /><br />
                </div>
              )
            }) : <></>
          }
          <br />
        </div>
      </div>
    )
  }
}
