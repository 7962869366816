import React, { useEffect, useState } from "react";

const BooleanControl = ({ json, onChangeValues }) => {
  const ref = React.useRef();
  const [selectedOption, setSelectedOption] = useState(json.selected);

  const handleDropdownChange = (event) => {
    const selectedValue = event.target.value;
    setSelectedOption(selectedValue);
    json.selected = selectedValue
    if(selectedValue!==''){
    delete json.error
    }
    onChangeValues()
  };

  useEffect(()=>{
    ref.current.value = json.selected;
  })

  return (
    <div style={{marginBottom:'10px',display:'flex',justifyContent:'space-between' }}>
      <label>{json.label}{json.mandatory === 'true'?<span className="mandatory">*</span>:''}:</label>
      <select ref={ref} style={{width:'50%'}} defaultValue={selectedOption} onChange={(e)=>handleDropdownChange(e,json.label)}>
      <option value="">Select an option</option>
        {json.options.map((option, index) => (
          <option key={index} value={option}>
            {option}
          </option>
        ))}
      </select>
    </div>
  );
};

export default BooleanControl;