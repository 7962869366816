import React from 'react';
import './index.css';
import { createRoot } from "react-dom/client";
import Home from './Routes/Home';
import {
  createBrowserRouter,
  RouterProvider,
  Outlet,
} from "react-router-dom";
import Canvas from './Components/Canvas/Canvas';
import Landingpage from './Components/Landing';

const AppLayout = () => (
    <Outlet />
);

const router = createBrowserRouter([
  {
    element: <AppLayout />,
    children: [
      {
        path: "/",
        element: <Landingpage />,
      },
      {
        path: "/home",
        element: <Home />,
      },
    ],
  },
  {
    path: "/canvas",
    element: <Canvas/>,
  },
  {
    path: "*",
    element: <Landingpage/>,
  },
]);

createRoot(document.getElementById("root")).render(
  <RouterProvider router={router} />
);

