// Import the PublicClientApplication from the '@azure/msal-browser' library.
import { PublicClientApplication } from '@azure/msal-browser';

// Configuration for Microsoft Authentication Library (MSAL).
const msalConfig = {
    auth: {
        // The client ID for your application obtained from Azure AD.
        clientId: 'd293a029-4f51-42eb-a93d-ff728b393c48',
        // The authority URL for authentication (common for multiple tenants).
        authority: 'https://login.microsoftonline.com/common',
    },
    cache: {
        // Use 'sessionStorage' to store authentication tokens for this session.
        cacheLocation: 'sessionStorage',
        // Do not store authentication state in cookies for security reasons.
        storeAuthStateInCookie: false,
    },
};

// Create an instance of the MSAL application using the provided configuration.
const msalInstance = new PublicClientApplication(msalConfig);

// Define the base URL for the API endpoints.
const baseURL = "https://sc0623.digisignals.ai/api/";

// Export the MSAL configuration, instance, and API base URL for use in other modules.
export { msalConfig, msalInstance, baseURL };
