import React, { useState } from 'react';
import SideBar from './SideBar';
import ResponsiveDialog from './ResponsiveDialog';

const iconMap = {
    "HomeIcon": require("../assets/Home.png"),
    "ProjectIcon": require("../assets/My-Projects-blue.png"),
    "NewProjectIcon": require("../assets/Create-a-new-project-_Blue.png"),
    "TemplateIcon": require("../assets/templates-blue.png"),
    "SettingIcon": require("../assets/SettingsIcon.png"),
    "HelpIcon": require("../assets/help.png"),
};

function SideNav({ sidebar }) {
    const [dialogOpen, setDialogOpen] = useState(false);

    const handleDialogOpen = () => {
        setDialogOpen(true);
    };

    const handleDialogClose = () => {
        setDialogOpen(false);
    };

    const createNewProjectItem = SideBar.find(item => item.title === 'Create a New Project');

    return (
        <div open={sidebar} className='sidebox' style={{ width: sidebar ? '200px' : '60px', position: 'fixed', top: 0, bottom: 0, left: 0 }}>
            <div style={{ paddingTop: '3rem' }}>
                {SideBar.map((item, index) => (
                    <div button key={index} onClick={item === createNewProjectItem ? handleDialogOpen : undefined} style={{ color: 'black', padding: '10px' }}>
                        <a href={item.path} style={{ textDecoration: 'none', cursor: 'pointer', justifyContent: sidebar ? 'unset' : 'center', display: 'flex', alignItems: 'center' }}>
                            <div>
                                <div style={{ flexDirection: 'row', display: 'flex', alignItems: 'center' }}>
                                    <div style={{ height: '18px', width: '18px' }}>
                                        <img width='18px' src={iconMap[item.icon]} alt="Icon" title={sidebar ?undefined: item.title } />
                                    </div>
                                    <div className='nameStyle' style={{ display: sidebar ? 'block' : 'none', marginLeft: '10px' }}>{item.title}</div>
                                </div>
                            </div>
                        </a>
                    </div>
                ))}
            </div>
            {createNewProjectItem && (
                <ResponsiveDialog open={dialogOpen} from='allProject' onClose={handleDialogClose} />
            )}
        </div>
    );
}

export default SideNav;
