import React, { useState } from "react";
import Header from "../Components/Header/Header";
import SideNav from "../Components/SideNav";
import AllProjectTable from "../Components/AllProject";
import { useNavigate } from "react-router-dom";

function Home() {
  const [sidebar, setSidebar] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const showSidebar = () => setSidebar(!sidebar);
  const navigate = useNavigate();

  if (sessionStorage.getItem('userName') === null) {
    navigate('/')
    sessionStorage.removeItem("ProjectName");
    sessionStorage.removeItem('userName');
    sessionStorage.removeItem('userId');
  }

  return (
    <div>
      <div className="loader-container" style={{ display: isLoading ? "flex" : "none", width: "100%" }}>
        <div className="spinner"></div>
      </div>
      <Header showSidebar={showSidebar} />

      <div style={{ display: "flex" }}>
        <SideNav sidebar={sidebar} />
        <div
          style={{
            flexGrow: 1,
            overflowY: "auto",
            padding: "1rem",
            marginLeft: sidebar ? "200px" : "60px",
            transition: "margin-left 0.3s ease",
          }}
        >
          <div className="headerStyle" style={{ marginTop: "30px" }}>
            Welcome to Azure Solution Composer!
          </div>
          <hr style={{ borderTop: "1px solid #bbb" }} />
          <div style={{ fontSize: "20px", paddingTop: "10px", color: "rgb(50, 49, 48)", fontFamily: ' "Segoe UI", "Segoe UI Web (West European)", "Segoe UI", -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif', fontWeight: 500 }}>
            Manage Files
          </div>
          <div style={{ paddingTop: "20px" }}>
            <AllProjectTable setIsLoading={setIsLoading} navigation={navigate} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;
