import React from 'react';
import data from '../../assets/component/componentsData.json';
import '../reactflow/dist/style.css';
import './index.css'
import SpeechToText from '../../assets/svg/sidebarIcons/speechToText.svg'
import TextToSpeech from '../../assets/svg/sidebarIcons/textToSpeech.svg'
import PostProcess from '../../assets/svg/sidebarIcons/postprocess.svg'
import SubtitleWriting from '../../assets/svg/sidebarIcons/subtitleWriting.svg'
import PreProcess from '../../assets/svg/sidebarIcons/preProcess.svg'
import Translation from '../../assets/svg/sidebarIcons/translation.svg'
import SpeechEvaluation from '../../assets/svg/sidebarIcons/speechEvaluation.svg'
const iconMap = {
  "SpeechToText": SpeechToText,
  "PostProcessSTT": PostProcess,
  "TextToSpeech": TextToSpeech,
  "Translation": Translation,
  "GenerateSubtitles": SubtitleWriting,
  "PreprocessTTSStep": PreProcess,
  "SpeechCorrectnessEvaluation": SpeechEvaluation,
};

export default () => {
  const onDragStart = (event, nodeType) => {
    event.dataTransfer.setData('StepId', nodeType.Component.StepID);
    event.dataTransfer.setData('StepIcon', nodeType.Component.StepIcon);
    event.dataTransfer.setData('StepOrder', nodeType.Component.StepOrder);
    event.dataTransfer.setData('StepName', nodeType.Component.StepName);
    event.dataTransfer.setData('inputs', nodeType.Inputs ? JSON.stringify(nodeType.Inputs) : '');
    event.dataTransfer.setData('outputs', nodeType.Outputs ? JSON.stringify(nodeType.Outputs) : '');
    event.dataTransfer.setData('node', JSON.stringify(nodeType));
    event.dataTransfer.effectAllowed = 'move';
  };
  return (
    <aside>
      <div className="dndnode sidebarText">Components</div>
      {data.length > 0 ? data.map((item, index) => {
        return (
          <div key={item.Component.StepID} onDragStart={(event) => onDragStart(event, item)} draggable className="dndnodeSidebar" style={{ display: 'flex', alignItems: 'center', justifyContent: 'left' }}>
            <div style={{ backgroundColor: '#1976d2', color: 'white', height: '100%', width: '4rem', display: 'flex', justifyContent: 'center' }}>
              <img width='22px' src={iconMap[item.Component.StepID]} alt="Icon" />
            </div>
            <span style={{ padding: '.5rem 1rem .5rem 3.5rem', width: '100%', marginLeft: '-2.6rem' }}>{item.Component.StepName}</span>
          </div>
        )
      }) : <></>}

    </aside>
  );
};
