import React, { Component } from "react";
import "./controllers.css";

class GroupController extends Component {
  constructor(props) {
    super(props);
    const { json } = this.props;
    this.state = {
      textInputs: json.groups.map((group) =>
        group.children.map((child) => ({
          value: child.text,
          error: this.isValidInput(child.text) ? "" : "Special characters are invalid",
        }))
      ),
    };
  }

  handleDropdownChange = (event, index, cIndex) => {
    const { json, onChangeValues } = this.props;
    const { value } = event.target;
    json.groups[index].children[cIndex].selected = value;
    onChangeValues();
  };

  handleTextInputChange = (event, index, cIndex) => {
    const { json, onChangeValues } = this.props;
    const { textInputs } = this.state;
    const val = event.target.value;
    let error = ''
    const updatedTextInputs = [...textInputs];
    updatedTextInputs[index][cIndex] = {
      value: val,
      error: this.isValidInput(val) ? "" : "Special characters and spaces are not allowed",
    };
    this.setState({ textInputs: updatedTextInputs });
    json.groups[index].children[cIndex].text = val;
    json.groups[index].children[cIndex].error = updatedTextInputs[index][cIndex].error;
    onChangeValues();
    json.error = json.groups[index].children[cIndex].error
  };

  isValidInput = (input) => {
    const alphanumericRegex = /^[a-zA-Z0-9/]*$/;
    return alphanumericRegex.test(input);
  };

  render() {
    const { json } = this.props;
    const { textInputs } = this.state;

    return (
      <div>
        <form>
          <label className="label">{json.label}:</label>
          {json.groups.map((group, index) => (
            <div key={index} style={{ marginBottom: "1.5rem" }}>
              {group.children.map((child, childIndex) => {
                if (child.type === "dropdown") {
                  return (
                    <div key={childIndex} className="label">
                      <label>{child.label}:</label>
                      <div>
                        <select
                          className="localeBox"
                          defaultValue={json.groups[index].children[childIndex].selected}
                          onChange={(event) =>
                            this.handleDropdownChange(event, index, childIndex)
                          }
                        >
                          <option value="">Select an option</option>
                          {child.options.map((option, optionIndex) => (
                            <option key={optionIndex} value={option}>
                              {option}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  );
                } else if (child.type === "text") {
                  return (
                    <div key={childIndex}>
                      <div className="label">
                        <label>{child.label}:</label>
                        {textInputs[index][childIndex].error && (
                          <span
                            style={{
                              color: "red",
                              paddingLeft: "10px",
                              fontSize: "0.7rem",
                            }}
                          >
                            {textInputs[index][childIndex].error}
                          </span>
                        )}
                      </div>
                      <div>
                        <input
                          type="text"
                          className="textbox"
                          value={textInputs[index][childIndex].value}
                          onChange={(event) =>
                            this.handleTextInputChange(event, index, childIndex)
                          }
                        />
                      </div>
                    </div>
                  );
                }
                return null;
              })}
            </div>
          ))}
        </form>
      </div>
    );
  }
}

export default GroupController;
