import React, { Component } from 'react';
import { getApiData } from '../Services/Services';
import Close from '../assets/svg/x.svg';
import DownArrow from '../assets/svg/downArrow.svg';
import CloseConnection from '../assets/svg/closeConnection.svg';
import Success from '../assets/svg/success.svg';
export default class NotificationBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showTable: false,
      deploymentResponse: "",
      apiCallTime: null,
      showInput: false,
      showOutput: false
    };
  }

  async onClickOfExport() {
    setTimeout(async () => {
      const res = await getApiData(sessionStorage.getItem("userId"), sessionStorage.getItem("projectId"))
      this.setState({ deploymentResponse: res, apiCallTime: new Date() });
      this.props.setHitOrchestrator(false)
    }, 5000);

  };
  handleDismissAll = () => {
    this.setState({ deploymentResponse: "", showStatusinNotification: false });
  };

  toggleInput = () => {
    this.setState(prevState => ({ showInput: !prevState.showInput }));
  };
  toggleOutput = () => {
    this.setState(prevState => ({ showOutput: !prevState.showOutput }));
  };
  render() {
    const { display, setIsNotification, hitOrchestratorcall, } = this.props;
    let showStatusinNotification = false;
    const { showInput, showOutput } = this.state;
    const maxContentLength = 20;
    let inputContent = ''
    let outputContent = ''
    if (hitOrchestratorcall) {
      this.onClickOfExport()
    }
    const { showTable, apiCallTime } = this.state;
    const deploymentResponse = this.state.deploymentResponse;
    let deploymentFailed = false
    if (!deploymentResponse.status) {
      deploymentFailed = true
      inputContent = deploymentResponse.status ? deploymentResponse.data?.input : deploymentResponse?.errorData?.input
      outputContent = deploymentResponse.status ? deploymentResponse.data?.output.PipelineStepInput : deploymentResponse?.errorData?.output
    }

    if (deploymentResponse !== '') {
      showStatusinNotification = true
    }
    return (
      <div style={{ display: display ? 'block' : 'none', height: '200px', overflowY: 'auto', backgroundColor: 'red' }}>
        <div className="slider-container open">
          <div
            className="slider-content"
            style={{
              position: 'absolute', display: 'flex', backgroundColor: 'white', height: '100vh', width: '25%', right: '0px', padding: '15px 10px', boxShadow: '0 0 3px 0 black',
              overflowY: 'auto',
            }}>
            <div style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
              <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: '-25px' }}>
                <div style={{ padding: '0px', color: 'black' }}>
                  <h2>Notifications</h2>
                </div>
                <div style={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
                  <img onClick={() => setIsNotification(false)} style={{ cursor: 'pointer' }} width='24px' src={Close} alt="Icon" />
                </div>
              </div>
              <div>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <div>
                    <div
                      style={{ color: "#1976d2", fontSize: "12px", }}>
                      More event in the active logs
                    </div>
                  </div>
                  <div style={{ display: 'flex', justifyContent: 'flex-end', width: '25%' }}>
                    <div
                      style={{ color: "#1976d2", fontSize: "12px", cursor: 'pointer', }} >
                      Dismiss All
                    </div>
                    <img onClick={this.handleDismissAll} style={{ cursor: 'pointer' }} width='22px' src={DownArrow} alt="Icon" />
                  </div>
                </div>
              </div>
              <div
                style={{ borderBottom: '1px solid #ddd', marginBottom: '5px', marginTop: '5px', }} />
              {showStatusinNotification && (
                <div>
                  <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div>
                      <div
                        style={{ color: "#1976d2", fontSize: "12px", }}>
                        {deploymentFailed ? (
                          <div style={{ display: 'flex', alignItems: 'center' }}>
                            <img width='12px' style={{ marginRight: '5px' }} src={CloseConnection} alt="Icon" /> Development Failed
                          </div>
                        ) : (
                          <div style={{ display: 'flex', alignItems: 'center' }}>
                            <img width='12px' style={{ marginRight: '5px' }} src={Success} alt="Icon" /> Development Succeeded
                          </div>
                        )}
                      </div>

                      <div
                        style={{ color: "Black", fontSize: "12px", marginTop: '10px' }} >
                        <h3>{deploymentResponse.message}</h3>
                        <div><b>Instance Name:</b></div><div> {deploymentResponse.status ? deploymentResponse.data?.name : deploymentResponse.errorData?.name}</div>
                        <div><b>Instance ID:</b> </div><div>{deploymentResponse.status ? deploymentResponse.data?.instanceId : deploymentResponse.errorData?.instanceId}</div>
                      </div>
                    </div>
                  </div>
                  <div style={{ marginBottom: '20px', marginTop: '20px' }}>
                    <button
                      style={{ backgroundColor: "#1976d2", border: '.2px solid black', width: "80px", color: "black", }}
                      variant="contained"
                      onClick={() => {
                        this.setState((prevState) => ({
                          showTable: !prevState.showTable,
                        }));
                      }}>View
                    </button>
                  </div>
                  <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <div
                      style={{ color: "black", fontSize: "12px", }}>
                      {apiCallTime && `${Math.floor((new Date() - apiCallTime) / 1000)} seconds ago`}
                    </div>
                  </div>
                  <div
                    style={{ borderBottom: '1px solid #ddd', marginBottom: '5px', marginTop: '5px', }} />
                  {showTable && (
                    <div style={{}}>
                      <div style={{ marginBottom: '10px', width: '100%', wordWrap: 'break-word', whiteSpace: 'pre-wrap' }}>
                        <strong>Input:</strong>
                        <div style={{ fontSize: '12px' }}>
                          {showInput ? deploymentResponse.status ? deploymentResponse.data?.input : deploymentResponse?.errorData?.input : `${deploymentResponse.status ? deploymentResponse.data?.input : deploymentResponse?.errorData?.input.substring(0, maxContentLength)}...`}
                        </div>
                        {inputContent?.length > maxContentLength && (
                          <button onClick={this.toggleInput} style={{
                            border: 'none',
                            backgroundColor: 'white',
                            color: '#1976d2',
                            cursor: 'pointer', margin: '0px'
                          }}>
                            {showInput ? 'View Less...' : 'View More...'}
                          </button>
                        )}
                      </div>
                      <div style={{ marginBottom: '100px', width: '100%', wordWrap: 'break-word', whiteSpace: 'pre-wrap' }}>
                        <strong>Output:</strong>
                        <div style={{ fontSize: '12px' }}>
                          {showOutput ? deploymentResponse.status ? deploymentResponse.data?.output.PipelineStepInput : deploymentResponse?.errorData?.output : `${deploymentResponse.status ? deploymentResponse.data?.output.PipelineStepInput : deploymentResponse?.errorData?.output.substring(0, maxContentLength)}...`}
                        </div>
                        {outputContent?.length > maxContentLength && (
                          <button onClick={this.toggleOutput} style={{
                            border: 'none',
                            backgroundColor: 'white',
                            color: '#1976d2',
                            cursor: 'pointer'
                          }}>
                            {showOutput ? 'View Less...' : 'View More...'}
                          </button>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
