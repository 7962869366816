import { React, useEffect, useState } from 'react';
import './loader.css'
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { msalInstance } from '../Config/Config';

const Landingpage = () => {
    const [userName, setUserName] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate()

     useEffect(() => {
        msalInstance.handleRedirectPromise()
            .then((s) => {
                if (s !== null && s.account != null) {
                    setIsLoading(true)
                    const username = s.account.username;
                    const name = s.account.name;
                    setUserName(username);
                    sessionStorage.setItem('userName', username)
                    sessionStorage.setItem('Name', name)
                    navigate('/home')
                  
                }
            })
            .catch((error) => {
                console.log(error);
            });
        if (window.location.pathname !== '/') {
            window.location.replace('/')
        }
    }, []);

    function signIn() {
        const loginScope = {
            scopes: ['User.Read'],
        };
        msalInstance.loginRedirect(loginScope);
        setIsLoading(true)
    }

    return (
        <>
            <ToastContainer />
            <div className="loader-container1" style={{ display: isLoading ? 'flex' : 'none' }}>
                <div className="spinner"></div>
            </div>
            <div className='App'>
                <h1 style={{
                    fontFamily: 'Segoe UI Regular',
                    fontWeight: '600'
                }}>Sign In</h1>
                <button onClick={signIn} style={{
                    fontFamily: 'Segoe UI Regular',
                    fontWeight: '600',
                    border: 'none',
                    padding: '12px 12px 12px 12px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                }}>
                    Sign In with Microsoft</button>
            </div>
        </>
    );
};

export default Landingpage;