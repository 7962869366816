import React, { Component } from "react";
import "./controllers.css"; 
class RangeControl extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedValue: this.props.json.text,
    };
  }

  handleRangeChange = (event) => {
    const selectedValue = event.target.value;
    this.setState({ selectedValue });
    this.props.json.text = selectedValue;
    if (selectedValue !== "") {
      delete this.props.json.error;
    }
    this.props.onChangeValues();
  };

  render() {
    const { json } = this.props;
    const { selectedValue } = this.state;

    return (
      <>
        <div className="label">
          <label>
            {json.label}
            {json.mandatory === "true" ? <span className="mandatory">*</span> : ""}
            :
          </label>
        </div>
        <div >
          <input
            className="slider"
            type="range"
            min={json.min}
            max={json.max}
            step={json.step}
            defaultValue={json.text}
            onChange={this.handleRangeChange}
            title={json.text}
          />
          <span>{selectedValue}</span>
        </div>
      </>
    );
  }
}

export default RangeControl;
