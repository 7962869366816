import React, { Component } from "react";

class URIController extends Component {
  handleInputChange = (event) => {
    const { json, onChangeValues } = this.props;
    const val = event.target.value;
    json.text = val;
    if (val !== "") {
      delete json.error;
    }
    onChangeValues();
  };

  render() {
    const { json } = this.props;

    return (
      <>
        <div className="label">
          <label>
            {json.label}
            {json.mandatory === "true" ? <span className="mandatory">*</span> : ""}
            :
          </label>
        </div>
        <div>
          <input
            className="textbox"
            type="url"
            defaultValue={json.text}
            onChange={this.handleInputChange}
          />
        </div>
      </>
    );
  }
}

export default URIController;
