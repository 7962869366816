
const SideBar = [
  {
    title: 'Home',
    icon: 'HomeIcon',
    path: "#",
  },
  {
    title: 'My Project',
    icon: 'ProjectIcon',
    path: "#",
  },
  {
    title: 'Create a New Project',
    icon: 'NewProjectIcon',
  },
  
  {
    title: 'Help Tutorials',
    icon: 'HelpIcon',
    path: "#",
  },

];


export default SideBar;
