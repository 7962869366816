import React, { useState, useEffect, useRef } from 'react'
import './header.css'
import { useNavigate } from "react-router-dom";
import Back from '../../assets/svg/arrow-left-short.svg';
import Menu from '../../assets/svg/list.svg';
import Notify from '../../assets/svg/bell.svg';
import Help from '../../assets/svg/question-circle.svg'
import User from '../../assets/svg/person-circle.svg';
import { msalInstance } from '../../Config/Config';


function Header({ showSidebar, onClickOutside, setIsNotification, isNotification }) {
  const [openSignout, setOpenSignout] = useState(false)
  let projectName = sessionStorage.getItem("ProjectName")
  const navigate = useNavigate();
  const userName = sessionStorage.getItem("userName");
  const Name = sessionStorage.getItem("Name");
  const userNameParts = userName?.split("@");
  const userNameToShow = userNameParts?.length > 0 ? userNameParts[0] : "";

  const ref = useRef(null);

  const handleDrawerOpen = () => {
    showSidebar();
  };

  const history = window.location.href
  let isCanvas = false;
  if (history.includes('/canvas')) {
    isCanvas = true
  }

  if (history.includes('/home')) {
    sessionStorage.removeItem("ProjectName")
    projectName = ''
  }

  async function handleSignOut() {
    try {
      const homeAccountId = sessionStorage.getItem('homeAccountId');
      await msalInstance.handleRedirectPromise()
        .then((tokenResponse) => {
          console.log("TokenResponce", tokenResponse)
          msalInstance.logoutRedirect({
            account: msalInstance.getAccountByHomeId(homeAccountId),
            postLogoutRedirectUri: window.location.origin,
          })
          sessionStorage.removeItem('ProjectName');
          sessionStorage.removeItem('userName');
          sessionStorage.removeItem('userId');
        })
        .catch((error) => {
          console.log("error", error)
        });


    } catch (error) {
      console.log(error);

    }

  }
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        onClickOutside && onClickOutside();
        setOpenSignout(false);
      }
    };
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, [onClickOutside]);

  return (
    <nav className="navigation">
      {isCanvas ?
        <div style={{ margin: '1rem 1rem', display: 'flex', alignItems: 'center', cursor: 'pointer' }} onClick={() => navigate('/home')}>
          <img width='28px' src={Back} alt="Icon" />
        </div>
        :
        <div style={{ margin: '1rem 1rem', display: 'flex', alignItems: 'center', cursor: 'pointer' }} onClick={handleDrawerOpen}>
          <img width='26px' src={Menu} alt="Icon" />
        </div>
      }
      <div className="brand-name">
        Azure AI | Solution Composer {projectName ? `- ${projectName}` : ''}
      </div>

      <div
        className="navigation-menu">
        <ul style={{ display: 'flex', alignItems: 'center' }}>
          {isCanvas ?
            <li>
              <img title="Notification" style={{ cursor: 'pointer' }} onClick={() => setIsNotification(!isNotification)} width='18px' src={Notify} alt="Icon" />
            </li> : ''}
          <li>
            <img src={Help} title="Help" alt="terminal" width='18px' />
          </li>
          <li>
            <div style={{ display: 'flex', flexDirection: 'column', lineHeight: '1.5', justifyContent: 'flex-end' }}>
              <span style={{ fontSize: '12px', marginRight: '5px' }}>
                {(Name)}
              </span>
              <span style={{ fontSize: '10px' }}>
                {sessionStorage.getItem("userName")}
              </span>
            </div>
          </li>
          <li>
            <div className='popupdiv' ref={ref} >
              <div title="Sign Out" style={{ cursor: 'pointer' }} onClick={() => setOpenSignout(!openSignout)}>
                <img src={User} alt="terminal" width='30px' />
              </div>
              <div className='popuptext' style={{ display: openSignout ? 'block' : 'none' }}>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <span style={{ fontSize: 'inherit' }}>DigiSignals</span>
                  <button
                    onClick={handleSignOut}
                    style={{ color: 'black', textTransform: 'none', textDecoration: 'none', background: 'none', cursor: 'pointer', fontSize: 'inherit', border: 'none', outline: 'none', }} >
                    Sign Out
                  </button>
                </div>
                <div style={{ display: 'flex', paddingBottom: '.5rem', paddingTop: '1rem' }}>
                  <div style={{ display: 'flex', justifyContent: 'center', width: '25%' }}>
                    <img src={User} title="Help" alt="terminal" width='80px' />
                  </div>
                  <div style={{ paddingTop: '1rem', marginLeft: '1rem', width: '75%' }}>
                    <div
                      style={{
                        wordWrap: 'break-word', fontFamily: '"Segoe UI","Segoe UI Web Regular","Segoe UI Symbol","Helvetica Neue","BBAlpha Sans","S60 Sans",Arial,sans-serif', fontSize: '20px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', display: 'block', fontWeight: 'bold',
                      }}>
                      {Name}
                    </div>
                    <div
                      style={{
                        wordWrap: 'break-word', fontFamily: '"Segoe UI","Segoe UI Web Regular","Segoe UI Symbol","Helvetica Neue","BBAlpha Sans","S60 Sans",Arial,sans-serif', fontSize: '14px', color: 'black', fontWeight: 'normal',
                      }}>
                      {sessionStorage.getItem("userName")}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </li>
        </ul>

      </div>

    </nav>
  )
}

export default Header