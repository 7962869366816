import styled, { css } from "styled-components";
import { baseURL } from '../Config/Config';


export function getList(id) {
  return fetch(`${baseURL}Project/AllProjectsOfUser/${id}`).then((data) =>
    data.json()
  );
}

export function createProject(id, projectName) {
  return fetch(`${baseURL}Project/CreateProject/${id}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*"
    },
    body: JSON.stringify({ projectName: projectName }),
  }).then((data) => data.json());
}

export function getProjectByID(uId, pId) {
  return fetch(`${baseURL}Project/ProjectsByProjectId/${uId}/${pId}`).then(
    (data) => data.json()
  );
}

export function getUserId(email) {
  return fetch(`${baseURL}User/RegisterEmail`, {
    method: "POST",
    headers: { "Content-Type": "application/json", "Access-Control-Allow-Origin": "*" },
    body: JSON.stringify({ userEmail: email }),
  }).then((data) => data.json());
}

export function saveProject(uId, pId, json) {
  return fetch(`${baseURL}Project/UpdateProject/${uId}/${pId}`, {
    method: "PUT",
    headers: { "Content-Type": "application/json", "Access-Control-Allow-Origin": "*" },
    body: JSON.stringify({ projectJSON: json }),
  }).then((data) => data.json());
}

export function publishJSON(uId, pId) {
  return fetch(`${baseURL}Project/PubishProject/${uId}/${pId}`, {
    method: "PUT",
    headers: { "Content-Type": "application/json", "Access-Control-Allow-Origin": "*" },
  }).then((data) => data.json());
}

export function createBlobContainer(email) {
  return fetch(`${baseURL}User/UserContainerFolder`, {
    method: "POST",
    headers: { "Content-Type": "application/json", "Access-Control-Allow-Origin": "*" },
    body: JSON.stringify({ userEmail: email }),
  }).then((data) => data.json());
}

export function createContainer(email) {
  return fetch(`${baseURL}User/ContainerFolder`, {
    method: "POST",
    headers: { "Content-Type": "application/json", "Access-Control-Allow-Origin": "*" },
    body: JSON.stringify({ userEmail: email }),
  }).then((data) => data.json());
}

export function getApiData(uId, pId) {
  return fetch(`${baseURL}Project/PublishProjectStatus/${uId}/${pId}`, {
    method: "GET",
    headers: { "Content-Type": "application/json", "Access-Control-Allow-Origin": "*" },
  }).then((data) => data.json());
}

export const ContextMenu = styled.div`
  position: absolute;
  width: 100px;
  background-color: white;
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  box-sizing: border-box;
  ${({ top, left }) => css`
    top: ${top}px;
    left: ${left}px;
  `}
  ul {
    box-sizing: border-box;
    padding: 10px;
    margin: 0;
    list-style: none;
  }
  ul li {
    padding: 5px 5px;
  }
  /* hover */
  ul li:hover {
    cursor: pointer;
    background-color: #E5E4E2;
  }
`;