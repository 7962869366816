import React, { Component } from 'react';
import { getList, getUserId, publishJSON } from '../Services/Services';
import { ToastContainer, toast } from 'react-toastify';
import flowchart from '../assets/flowchart1.png';
import '../../src/App.css'
import { createContainer } from '../Services/Services';
const columns = [
  { id: "icon", label: "" },
  { id: "projectName", label: "Project Name" },
  { id: "creationDate", label: "Creation Date" },
  { id: "modified", label: "Modified Date" },
  { id: "publishedDate", label: "Published Date" },
];

function formatDate(date) {
  var d = new Date(date),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2)
    month = '0' + month;
  if (day.length < 2)
    day = '0' + day;

  return [day, month, year].join('-');
}

// sorting of data arror function
const SortArrow = ({ order, property, onClick }) => (
  <span className={`sort-arrow ${order === 'asc' ? 'asc' : 'desc'}`} onClick={() => onClick(property)}>
    {order === 'asc' ? '\u25B2' : '\u25BC'}
  </span>
);


export default class AllProject extends Component {
  constructor(props) {
    super(props);
    this.state = {
      orderBy: '',
      order: "asc",
      page: 0,
      rowsPerPage: 5,
      rows: [],
    };
  }

  createNewContainer = async (username) => {
    const isCreate = await createContainer(username)
  }
  componentDidMount = async () => {
    this.props.setIsLoading(true)
    const userData = await getUserId(sessionStorage.getItem('userName'))
    if (userData.status) {
      this.createNewContainer(sessionStorage.getItem('userName'));
      const data = await getList(userData.userId);
      sessionStorage.setItem('userId', userData.userId)
      this.setState({ rows: data.data });
      this.props.setIsLoading(false)
    }

  };

  onProjectClick = (e) => {
    sessionStorage.setItem("projectId", e.project_id)
    sessionStorage.setItem("ProjectName", e.project_name)
    this.props.navigation('/canvas');
  }

  // On project Publish on Publish button
  onProjectPublished = async (pid) => {
    const uid = sessionStorage.getItem('userId')
    const exportJson = await publishJSON(uid, pid)
    if (exportJson.status) {
      const data = exportJson.data
      const fileName = data.Pipeline.Name;
      const newData = new Blob([JSON.stringify([data])], { type: "text/json" });
      const jsonURL = window.URL.createObjectURL(newData);
      const link = document.createElement("a");
      document.body.appendChild(link);
      link.href = jsonURL;
      link.setAttribute("download", fileName);
      link.click();
      document.body.removeChild(link);
    } else {
      toast(exportJson.message, { position: "top-right", hideProgressBar: true });
    }
  }

  // Sorting of data
  handleSort = (property) => {
    const { orderBy, order, rows } = this.state;
    const isAsc = orderBy === property && order === 'asc';
    const newOrder = isAsc ? 'desc' : 'asc';

    const sortedRows = [...rows].reverse((a, b) => {
      const valueA = a[property];
      const valueB = b[property];

      if (valueA === null || valueA === undefined) return isAsc ? -1 : 1;
      if (valueB === null || valueB === undefined) return isAsc ? 1 : -1;

      if (["creationDate", "modified", "publishedDate"].includes(property)) {
        return isAsc ? new Date(valueA) - new Date(valueB) : new Date(valueB) - new Date(valueA);
      } else {
        return isAsc ? valueA.toString().localeCompare(valueB.toString()) : valueB.toString().localeCompare(valueA.toString());
      }
    });

    this.setState({
      orderBy: property,
      order: newOrder,
      rows: sortedRows,
    });
  };



  render() {
    const { orderBy, order, page, rowsPerPage, rows } = this.state;
    const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows?.length - page * rowsPerPage);

    return (
      <>
        <ToastContainer />
        {rows?.length > 0 ?
          <div className="table-container">
            <table>
              <thead>
                <tr>
                  {columns.map((item) => (
                    <th key={item.id} className="table-header" style={{ paddingLeft: '5px' }} onClick={() => this.handleSort(item.id)}>
                      <span className={`table-sort-label ${orderBy === item.id ? order : 'asc'}`}>
                        {item.label}
                      </span>
                      <SortArrow order={order} property={item.id} onClick={this.handleSort} />
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {rows?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((item) => (
                  <tr key={item.project_id}>
                    <td style={{ textAlign: 'center', width: '0.2rem', padding: '10px' }}>
                      <img src={flowchart} alt="terminal" style={{ height: '20px', width: '20px', }} />
                    </td>
                    <td
                      style={{
                        cursor: 'pointer', padding: '10px', fontSize: '13px', fontWeight: '200', paddingLeft: '5px'
                        , width: '300px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap',
                      }}
                      onClick={() => this.onProjectClick(item)}>
                      {item.project_name}
                    </td>
                    <td style={{ padding: '10px', fontSize: '13px', fontWeight: '200', paddingLeft: '5px' }}>
                      {item.created_date ? formatDate(item.created_date) : item.created_date}
                    </td>
                    <td style={{ padding: '10px', fontSize: '13px', fontWeight: '200', paddingLeft: '5px' }}>
                      {item.modified_date ? formatDate(item.modified_date) : item.modified_date}
                    </td>
                    <td style={{ padding: '10px', fontSize: '13px', fontWeight: '200', paddingLeft: '5px' }}>
                      {item.published_date ? formatDate(item.published_date) : item.published_date}
                    </td>
                  </tr>
                ))}
                {emptyRows > 0 && (
                  <tr style={{ height: 53 * emptyRows }}>
                    <td colSpan={5} />
                  </tr>
                )}
              </tbody>
            </table>
            <div className="table-pagination">
              <div className="table-rows-per-page">
                Rows per page:
                <select
                  value={rowsPerPage}
                  onChange={(event) => this.setState({ rowsPerPage: parseInt(event.target.value, 10), page: 0 })}>
                  <option value={5}>5</option>
                  <option value={10}>10</option>
                  <option value={25}>25</option>
                </select>
              </div>
              <div className="table-page">
                Page:
                <button onClick={() => this.setState({ page: page - 1 })} disabled={page === 0}>Previous</button>
                <span>{page + 1}</span>
                <button onClick={() => this.setState({ page: page + 1 })} disabled={(page + 1) * rowsPerPage >= rows.length}>Next</button>
              </div>
            </div>
          </div>
          :
          <div>You don't have any projects created yet. Start by clicking on the "Create a new project" button to create your first project.</div>
        }
      </>
    );
  }
}
