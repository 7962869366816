import React, { Component } from "react";

class LocaleControl extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedOption: this.props.json.selected,
    };
  }

  handleDropdownChange = (event) => {
    const selectedValue = event.target.value;
    this.setState({ selectedOption: selectedValue });
    const { json, onChangeValues } = this.props;
    json.selected = selectedValue;
    if (selectedValue !== "") {
      delete json.error;
    }
    onChangeValues();
  };

  render() {
    const { json } = this.props;
    const { selectedOption } = this.state;

    return (
      <>
        <div className="label">
          <label>{json.label}
            {json.mandatory === "true" ? <span className="mandatory">*</span> : ""}
            :
          </label>
        </div>
        <div>
          <select
            className="localeBox"
            defaultValue={selectedOption}
            onChange={this.handleDropdownChange}
          >
            <option className="" value="">
              Select an option
            </option>
            {json.options.map((option, index) => (
              <option key={index} value={option}>
                {option}
              </option>
            ))}
          </select>
        </div>
      </>
    );
  }
}

export default LocaleControl;
