import React, { Component } from "react";

class RadioButtonControl extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedOption: "",
    };
  }

  componentDidMount() {
    const { json } = this.props;
    if (json.selected === true) {
      this.setState({ selectedOption: "True" });
    } else if (json.selected === false) {
      this.setState({ selectedOption: "False" });
    }
  }

  handleRadioChange = (value) => {
    this.setState({ selectedOption: value });
    const { json, onChangeValues } = this.props;
    json.selected = value === "True"; 
    delete json.error;
    onChangeValues();
  };

  render() {
    const { json } = this.props;
    const { selectedOption } = this.state;

    return (
      <>
        <div className="label">
          <label>
            {json.label}
            {json.mandatory === "true" ? <span className="mandatory">*</span> : ""}
            :
          </label>
        </div>
        <div className="radio-container">
          {json.options.map((option, index) => (
            <div key={index} className="radio">
              <input
                style={{ margin: "0px 5px 0px 0px" }}
                type="radio"
                value={option}
                checked={json.selected === (option === "True")} 
                onChange={() => this.handleRadioChange(option, json.label)}
              />
              <span>{option}</span>
            </div>
          ))}
        </div>
      </>
    );
  }
}

export default RadioButtonControl;
