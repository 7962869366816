import React, { Component } from "react";

class NumericInputControl extends Component {
  constructor(props) {
    super(props);
    this.state = {
      inputValue: this.props.json.text || "",
    };
  }

  handleInputChange = (event) => {
    const { json, onChangeValues } = this.props;
    const val = event.target.value;
    this.setState({ inputValue: val });
    json.text = val;
    if (val !== "") {
      delete json.error;
    }
    onChangeValues();
  };

  componentDidUpdate(prevProps) {
    if (prevProps.json.text !== this.props.json.text) {
      this.setState({ inputValue: this.props.json.text });
    }
  }

  render() {
    const { json } = this.props;

    return (
      <>
        <div className="label">
          <label>
            {json.label}
            {json.mandatory === "true" ? <span className="mandatory">*</span> : ""}
            :
          </label>
        </div>
        <div>
          <input
            className="numericbox"
            type="number"
            min={0}
            value={this.state.inputValue}
            onChange={this.handleInputChange}
          />
        </div>
      </>
    );
  }
}

export default NumericInputControl;