import React, { useState } from "react";

const DropdownControl = ({ json,onChangeValues }) => {
  const [selectedOption, setSelectedOption] = useState(json.selected);

  const handleDropdownChange = (event) => {
    const selectedValue = event.target.value;
    setSelectedOption(selectedValue);
    json.selected = selectedValue
    if(selectedValue!==''){
      delete json.error
      }
    onChangeValues()
  };

  return (
    <div style={{marginBottom:'10px',display:'flex',justifyContent:'space-between',flexDirection:'column'}}>
      <label>{json.label}{json.mandatory === 'true'?<span className="mandatory">*</span>:''}:</label>

      <select style={{width:'100%',height:'30px'}} value={selectedOption} onChange={handleDropdownChange}>
      <option value="">Select an option</option>
        {json.options.map((option, index) => (
          <option key={index} value={option}>
            {option}
          </option>
        ))}
      </select>
    </div>
  );
};

export default DropdownControl;